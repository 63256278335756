import React, { Component } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/styles'
import { teal } from '@material-ui/core/colors'
import { IconButton } from '@material-ui/core'
import { Pencil } from 'mdi-material-ui'
import { Link } from './../components'

const styles = theme => ({
    button: {
        color: teal[500]
    }
})

class ActionUpdateButton extends Component {
    render() {
        const { path } = this.props
        const { button } = this.props.classes

        return (
            <IconButton
                to={path}
                component={Link}
                className={classNames(button, 'table-item')}
            >
                <Pencil />
            </IconButton>
        )
    }
}

export default withStyles(styles)(ActionUpdateButton)
