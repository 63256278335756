import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { User as UserForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class UsersUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            name: '',
            avatar: '',
            email: '',
            phone: '',
            expiryDate: null,
            role: 'user',
            aid: '',
            discountRate: '',
            commissionRate: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { user } = await axios.get(pathJoin('users', id), { progress: true })
        const {
            name,
            avatar,
            email,
            phone,
            expiryDate,
            role,
            aid,
            discountRate,
            commissionRate
        } = user

        this.setState({
            key: shortid.generate(),
            name,
            avatar,
            email,
            phone,
            expiryDate,
            role,
            aid,
            discountRate,
            commissionRate
        })
    }

    render() {
        const {
            key,
            name,
            avatar,
            email,
            phone,
            expiryDate,
            role,
            aid,
            discountRate,
            commissionRate
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Хэрэглэгч"
                roles={[
                    'admin'
                ]}
            >
                <UserForm
                    key={key}
                    method="put"
                    path={pathJoin('users', id)}
                    name={name}
                    avatar={avatar}
                    email={email}
                    phone={phone}
                    expiryDate={expiryDate}
                    role={role}
                    aid={aid}
                    discountRate={discountRate}
                    commissionRate={commissionRate}
                />
            </Dashboard>
        )
    }
}
