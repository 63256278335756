import React, { Component } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/styles'
import { Fab } from '@material-ui/core'

const styles = theme => ({
    top: {
        top: 24
    },
    left: {
        left: 24
    },
    right: {
        right: 24
    },
    bottom: {
        bottom: 24
    },
    fab: {
        position: 'fixed'
    }
})

class BaseFab extends Component {
    static defaultProps = {
        top: false,
        left: false,
        right: false,
        bottom: false
    }

    render() {
        const {
            top,
            left,
            right,
            bottom,
            classes,
            ...rest
        } = this.props

        return (
            <div className={classNames(classes.fab, {
                [classes.top]: top,
                [classes.left]: left,
                [classes.right]: right,
                [classes.bottom]: bottom
            })}>
                <Fab {...rest} />
            </div>
        )
    }
}

export default withStyles(styles)(BaseFab)
