import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import { Coupon as CouponForm } from './../forms'

export default class CouponsNew extends Component {
    constructor(props) {
        super(props)

        this.state = {
            //
        }
    }

    render() {
        return (
            <Dashboard
                title="Бэлгийн карт"
                roles={[
                    'admin'
                ]}
            >
                <CouponForm
                    method="post"
                    path="coupons"
                />
            </Dashboard>
        )
    }
}
