import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { Dashboard } from './../layouts'
import {
    ActionDelete,
    ActionNewButton,
    ActionUpdateButton,
    BaseTable,
    BaseTableActions
} from './../components'
import { pathJoin } from './../utils/helpers'

const PeriodCell = ({ period, type }) => {
    let translation = 'хоног'

    switch (type) {
        case 'months':
            translation = 'сар'
            break
        case 'years':
            translation = 'жил'
            break
    }

    return (
        <span>
            {period} {translation}
        </span>
    )
}

const columns = [
    {
        name: 'name',
        title: 'Нэр'
    },
    {
        name: 'period',
        getCellValue: PeriodCell,
        title: 'Хугацаа'
    },
    {
        name: 'price',
        title: 'Үнэ'
    },
    {
        name: 'createdAt',
        title: 'Огноо'
    },
    {
        name: 'EDIT',
        getCellValue: ({ _id }) => <ActionUpdateButton path={pathJoin('plans', _id)} />
    }
]

const dateColumns = [
    'createdAt'
]

const currencyColumns = [
    'price'
]

const sortingStateColumnExtensions = [
    {
        columnName: 'period',
        sortingEnabled: false
    }
]

export default class Plans extends Component {
    constructor(props) {
        super(props)
        this.table = React.createRef()
    }

    fetchData = () => {
        return this.table.current.fetchData()
    }

    render() {
        return (
            <Dashboard
                title="Багц"
                roles={[
                    'admin'
                ]}
            >
                <BaseTableActions>
                    <Grid item>
                        <ActionNewButton path="/plans/new" />
                    </Grid>
                    <Grid item>
                        <ActionDelete
                            path="/plans/mass/destroy"
                            onDelete={this.fetchData}
                        />
                    </Grid>
                </BaseTableActions>
                <BaseTable
                    path="plans"
                    columns={columns}
                    dateColumns={dateColumns}
                    currencyColumns={currencyColumns}
                    sortingStateColumnExtensions={sortingStateColumnExtensions}
                    innerRef={this.table}
                />
            </Dashboard>
        )
    }
}
