import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Award as AwardForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class AwardsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            date: null,
            description: ''
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { award } = await axios.get(pathJoin('awards', id), { progress: true })
        const {
            date,
            description
        } = award

        this.setState({
            key: shortid.generate(),
            date,
            description
        })
    }

    render() {
        const {
            key,
            date,
            description
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Шагнал"
                roles={[
                    'admin'
                ]}
            >
                <AwardForm
                    key={key}
                    method="put"
                    path={pathJoin('awards', id)}
                    date={date}
                    description={description}
                />
            </Dashboard>
        )
    }
}
