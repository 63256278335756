import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import { Post as PostForm } from './../forms'

export default class PostsNew extends Component {
    render() {
        return (
            <Dashboard
                title="Мэдээ"
                roles={[
                    'admin'
                ]}
            >
                <PostForm
                    method="post"
                    path="posts"
                />
            </Dashboard>
        )
    }
}
