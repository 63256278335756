import React, { Component } from 'react'
import { Dashboard } from './../layouts'

export default class Home extends Component {
    render() {
        return (
            <Dashboard
                title="Нүүр"
                roles={[
                    'admin',
                    'editor'
                ]}
            >

            </Dashboard>
        )
    }
}
