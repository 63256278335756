import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Grid,
    Button,
    MenuItem,
    TextField
} from '@material-ui/core'
import { Alert } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

class PlanForm extends Component {
    static defaultProps = {
        name: '',
        description: '',
        period: '',
        type: 'days',
        price: '',
        productId: ''
    }

    constructor(props) {
        super(props)

        const {
            name,
            description,
            period,
            type,
            price,
            productId
        } = props

        this.state = {
            errors: {},
            open: false,
            disabled: false,
            name,
            description,
            period,
            type,
            price,
            productId
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    reset = () => {
        this.setState({
            name: '',
            description: '',
            period: '',
            type: 'days',
            price: '',
            productId: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            disabled: true
        })

        const {
            method,
            path
        } = this.props

        const {
            name,
            description,
            period,
            type,
            price,
            productId
        } = this.state

        try {
            await axios[method](path, {
                name,
                description,
                period,
                type,
                price,
                productId
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                open: true,
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            disabled,
            name,
            description,
            period,
            type,
            price,
            productId
        } = this.state
        const { formControl } = this.props.classes

        return (
            <div>
                <Alert
                    open={open}
                    onClose={this.closeSnackbar}
                />
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={5}
                        xl={4}
                    >
                        <form
                            onSubmit={this.storeOrUpdate}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                label="Нэр"
                                value={name}
                                error={!!errors.name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Тайлбар"
                                value={description}
                                error={!!errors.description}
                                onChange={this.handleChange('description')}
                                variant="outlined"
                                margin="normal"
                                rows="4"
                                multiline
                                fullWidth
                            />
                            <TextField
                                label="Хугацаа"
                                value={period}
                                error={!!errors.period}
                                onChange={this.handleChange('period')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Төрөл"
                                value={type}
                                error={!!errors.type}
                                onChange={this.handleChange('type')}
                                variant="outlined"
                                margin="normal"
                                children={[
                                    <MenuItem value="days">
                                        Хоног
                                    </MenuItem>,
                                    <MenuItem value="months">
                                        Сар
                                    </MenuItem>,
                                    <MenuItem value="years">
                                        Жил
                                    </MenuItem>
                                ]}
                                fullWidth
                                select
                            />
                            <TextField
                                label="Үнэ"
                                value={price}
                                error={!!errors.price}
                                onChange={this.handleChange('price')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="In-App Purchase Product ID"
                                value={productId}
                                error={!!errors.productId}
                                onChange={this.handleChange('productId')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <div className={formControl}>
                                <Button
                                    disabled={disabled}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(PlanForm)
