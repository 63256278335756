import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import {
    Grid,
    Button,
    TextField,
    MenuItem
} from '@material-ui/core'
import { Alert } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

class SubjectForm extends Component {
    static defaultProps = {
        selections: [],
        name: '',
        grade: ''
    }

    constructor(props) {
        super(props)

        const {
            selections,
            name,
            grade
        } = props

        this.state = {
            errors: {},
            open: false,
            disabled: false,
            selections,
            name,
            grade
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    reset = () => {
        this.setState({
            name: '',
            grade: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            disabled: true
        })

        const {
            method,
            path
        } = this.props

        const {
            name,
            grade
        } = this.state

        try {
            await axios[method](path, {
                name,
                grade
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                open: true,
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            disabled,
            selections,
            name,
            grade
        } = this.state
        const { formControl } = this.props.classes

        return (
            <div>
                <Alert
                    open={open}
                    onClose={this.closeSnackbar}
                />
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={5}
                        xl={4}
                    >
                        <form
                            onSubmit={this.storeOrUpdate}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                label="Нэр"
                                value={name}
                                error={!!errors.name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Анги"
                                value={grade}
                                error={!!errors.grade}
                                onChange={this.handleChange('grade')}
                                variant="outlined"
                                margin="normal"
                                children={selections.map(({ _id, name }) =>
                                    <MenuItem
                                        value={_id}
                                        key={shortid.generate()}
                                    >
                                        {name}
                                    </MenuItem>
                                )}
                                fullWidth
                                select
                            />
                            <div className={formControl}>
                                <Button
                                    disabled={disabled}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(SubjectForm)
