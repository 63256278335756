import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import { Category as CategoryForm } from './../forms'

export default class CategoriesNew extends Component {
    render() {
        return (
            <Dashboard
                title="Ангилал"
                roles={[
                    'admin'
                ]}
            >
                <CategoryForm
                    method="post"
                    path="categories"
                />
            </Dashboard>
        )
    }
}
