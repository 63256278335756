import React from 'react'
import {
    Route,
    Router,
    Switch
} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import {
    Awards,
    AwardsNew,
    AwardsUpdate,
    Categories,
    CategoriesNew,
    CategoriesUpdate,
    Coupons,
    CouponsNew,
    CouponsUpdate,
    Forbidden,
    Grades,
    GradesNew,
    GradesUpdate,
    Home,
    Lessons,
    LessonsNew,
    LessonsUpdate,
    Login,
    NotFound,
    Orders,
    Plans,
    PlansNew,
    PlansUpdate,
    Posts,
    PostsNew,
    PostsUpdate,
    Products,
    ProductsNew,
    ProductsUpdate,
    Questions,
    QuestionsNew,
    QuestionsUpdate,
    Settings,
    Slides,
    SlidesNew,
    Subjects,
    SubjectsNew,
    SubjectsUpdate,
    Users,
    UsersNew,
    UsersUpdate,
    Videos,
    VideosNew,
    VideosUpdate
} from './pages'

const history = createBrowserHistory()

export { history }

export default () => (
    <Router history={history}>
        <Switch>
            <Route
                path="/signin"
                component={Login}
            />
            <Route
                path="/"
                component={Home}
                exact
            />
            <Route
                path="/awards/new"
                component={AwardsNew}
            />
            <Route
                path="/awards/:id"
                component={AwardsUpdate}
            />
            <Route
                path="/awards"
                component={Awards}
            />
            <Route
                path="/categories/new"
                component={CategoriesNew}
            />
            <Route
                path="/categories/:id"
                component={CategoriesUpdate}
            />
            <Route
                path="/categories"
                component={Categories}
            />
            <Route
                path="/coupons/new"
                component={CouponsNew}
            />
            <Route
                path="/coupons/:id"
                component={CouponsUpdate}
            />
            <Route
                path="/coupons"
                component={Coupons}
            />
            <Route
                path="/grades/new"
                component={GradesNew}
            />
            <Route
                path="/grades/:id"
                component={GradesUpdate}
            />
            <Route
                path="/grades"
                component={Grades}
            />
            <Route
                path="/lessons/new"
                component={LessonsNew}
            />
            <Route
                path="/lessons/:id"
                component={LessonsUpdate}
            />
            <Route
                path="/lessons"
                component={Lessons}
            />
            <Route
                path="/plans/new"
                component={PlansNew}
            />
            <Route
                path="/plans/:id"
                component={PlansUpdate}
            />
            <Route
                path="/plans"
                component={Plans}
            />
            <Route
                path="/posts/new"
                component={PostsNew}
            />
            <Route
                path="/posts/:id"
                component={PostsUpdate}
            />
            <Route
                path="/posts"
                component={Posts}
            />
            <Route
                path="/products/new"
                component={ProductsNew}
            />
            <Route
                path="/products/:id"
                component={ProductsUpdate}
            />
            <Route
                path="/products"
                component={Products}
            />
            <Route
                path="/questions/new"
                component={QuestionsNew}
            />
            <Route
                path="/questions/:id"
                component={QuestionsUpdate}
            />
            <Route
                path="/questions"
                component={Questions}
            />
            <Route
                path="/orders"
                component={Orders}
            />
            <Route
                path="/settings"
                component={Settings}
            />
            <Route
                path="/slides/new"
                component={SlidesNew}
            />
            <Route
                path="/slides"
                component={Slides}
            />
            <Route
                path="/subjects/new"
                component={SubjectsNew}
            />
            <Route
                path="/subjects/:id"
                component={SubjectsUpdate}
            />
            <Route
                path="/subjects"
                component={Subjects}
            />
            <Route
                path="/users/new"
                component={UsersNew}
            />
            <Route
                path="/users/:id"
                component={UsersUpdate}
            />
            <Route
                path="/users"
                component={Users}
            />
            <Route
                path="/videos/new"
                component={VideosNew}
            />
            <Route
                path="/videos/:id"
                component={VideosUpdate}
            />
            <Route
                path="/videos"
                component={Videos}
            />
            <Route
                path="/not/allowed"
                component={Forbidden}
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
)
