import React, { Component } from 'react'
import {
    Box,
    Grid,
    Button,
    Link
} from '@material-ui/core'
import { Dashboard } from './../layouts'
import {
    BaseDatePicker,
    BaseTable,
    Confirm,
    ExportCSV,
    Link as RouterLink
} from './../components'
import { pathJoin } from './../utils/helpers'

const UserCell = ({ user }) => (
    <span>
        {user &&
            <Link
                component={RouterLink}
                to={pathJoin('users', user._id)}
            >
                {user.uid}
            </Link>
        }
    </span>
)

const UserEmailCell = ({ user }) => (
    <span>
        {user && user.email}
    </span>
)

const UserPhoneCell = ({ user }) => (
    <span>
        {user && user.phone}
    </span>
)

const CouponCell = ({ coupon }) => (
    <span>
        {coupon && coupon.code}
    </span>
)

const StatusCell = ({ status }) => (
    <span>
        {status === 'pending' ? 'Хүлээгдэж байгаа' : 'Төлбөр төлсөн'}
    </span>
)

const AidCell = ({ aid, discountRate }) => (
    <span>
        {!!aid && `${aid} (${discountRate}%)`}
    </span>
)

const PeriodCell = ({ period, type }) => {
    let translation = 'хоног'

    switch (type) {
        case 'months':
            translation = 'сар'
            break
        case 'years':
            translation = 'жил'
            break
    }

    return (
        <span>
            {period} {translation}
        </span>
    )
}

const columns = [
    {
        name: 'number',
        title: 'ID'
    },
    {
        name: 'user',
        getCellValue: UserCell,
        title: 'Хэрэглэгч'
    },
    {
        name: 'user.phone',
        getCellValue: UserEmailCell,
        title: 'И-мэйл хаяг'
    },
    {
        name: 'user.email',
        getCellValue: UserPhoneCell,
        title: 'Утас'
    },
    {
        name: 'coupon',
        getCellValue: CouponCell,
        title: 'Купон код'
    },
    {
        name: 'aid',
        getCellValue: AidCell,
        title: 'Түншлэлийн код'
    },
    {
        name: 'period',
        getCellValue: PeriodCell,
        title: 'Хугацаа'
    },
    {
        name: 'amount',
        title: 'Дүн'
    },
    {
        name: 'method',
    },
    {
        name: 'status',
        getCellValue: StatusCell,
        title: 'Төлөв'
    },
    {
        name: 'createdAt',
        title: 'Огноо'
    }
]

const dateColumns = [
    'createdAt'
]

const currencyColumns = [
    'amount'
]

const sortingStateColumnExtensions = [
    {
        columnName: 'user',
        sortingEnabled: false
    },
    {
        columnName: 'user.email',
        sortingEnabled: false
    },
    {
        columnName: 'user.phone',
        sortingEnabled: false
    },
    {
        columnName: 'coupon',
        sortingEnabled: false
    },
    {
        columnName: 'period',
        sortingEnabled: false
    }
]

export default class Orders extends Component {
    constructor(props) {
        super(props)

        this.table = React.createRef()

        this.state = {
            disabled: false,
            from: null,
            to: null
        }
    }

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        })
    }

    fetchData = async () => {
        this.setState({
            disabled: true
        })

        await this.table.current.fetchData()

        this.setState({
            disabled: false
        })
    }

    render() {
        const {
            disabled,
            from,
            to
        } = this.state

        return (
            <Dashboard
                title="Төлбөр"
                roles={[
                    'admin'
                ]}
            >
                <Box mb={3}>
                    <Grid
                        spacing={2}
                        container
                    >
                        <Grid item>
                            <BaseDatePicker
                                label="Эхлэх"
                                value={from}
                                onChange={this.handleDateChange('from')}
                            />
                        </Grid>
                        <Grid item>
                            <BaseDatePicker
                                label="Дуусах"
                                value={to}
                                onChange={this.handleDateChange('to')}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                onClick={this.fetchData}
                                disabled={disabled}
                                style={{
                                    margin: '10px 0'
                                }}
                            >
                                Хайх
                            </Button>
                        </Grid>
                        <Grid item>
                            <div style={{
                                margin: '10px 0'
                            }}>
                                <Confirm onConfirm={this.fetchData} />
                            </div>
                        </Grid>
                        <Grid item>
                            <div style={{
                                margin: '10px 0'
                            }}>
                                <ExportCSV path="/api/orders/export/csv" />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <BaseTable
                    path="orders"
                    columns={columns}
                    dateColumns={dateColumns}
                    currencyColumns={currencyColumns}
                    sortingStateColumnExtensions={sortingStateColumnExtensions}
                    innerRef={this.table}
                    customParams={{
                        from: from ? from.format('YYYY-MM-DD') : undefined,
                        to: to ? to.format('YYYY-MM-DD') : undefined,
                        populate: [
                            'user', 'coupon'
                        ]
                    }}
                />
            </Dashboard>
        )
    }
}
