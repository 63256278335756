import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Question as QuestionForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class PostsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formKey: shortid.generate(),
            key: '',
            value: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { question } = await axios.get(pathJoin('questions', id), { progress: true })
        const {
            key,
            value
        } = question

        this.setState({
            formKey: shortid.generate(),
            key,
            value
        })
    }

    render() {
        const {
            formKey,
            key,
            value
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Түгээмэл асуулт"
                roles={[
                    'admin'
                ]}
            >
                <QuestionForm
                    key={formKey}
                    method="put"
                    path={pathJoin('questions', id)}
                    questionKey={key}
                    value={value}
                />
            </Dashboard>
        )
    }
}
