import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Coupon as CouponForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class CouponsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            code: '',
            period: '',
            type: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { coupon } = await axios.get(pathJoin('coupons', id), { progress: true })
        const {
            code,
            period,
            type
        } = coupon

        this.setState({
            key: shortid.generate(),
            code,
            period,
            type
        })
    }

    render() {
        const {
            key,
            code,
            period,
            type
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Бэлгийн карт"
                roles={[
                    'admin'
                ]}
            >
                <CouponForm
                    key={key}
                    method="put"
                    path={pathJoin('coupons', id)}
                    code={code}
                    period={period}
                    type={type}
                />
            </Dashboard>
        )
    }
}
