import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import { Slide as SlideForm } from './../forms'

export default class SlidesNew extends Component {
    render() {
        return (
            <Dashboard
                title="Слайд"
                roles={[
                    'admin'
                ]}
            >
                <SlideForm
                    method="post"
                    path="slides"
                />
            </Dashboard>
        )
    }
}
