import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Video as VideoForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class VideosUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            selections: [],
            name: '',
            description: '',
            coverSrc: '',
            filePath: '',
            categories: [],
            tags: [],
            isFree: false,
            order: 'A'
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { data } = await axios.get('categories', {
            progress: true,
            params: {
                sortBy: 'name',
                sortOrder: 'asc'
            }
        })

        const { video } = await axios.get(pathJoin('videos', id), { progress: true })
        const {
            name,
            description,
            coverSrc,
            filePath,
            categories,
            tags,
            isFree,
            order
        } = video

        this.setState({
            key: shortid.generate(),
            selections: data,
            name,
            description,
            coverSrc,
            filePath,
            categories,
            tags,
            isFree,
            order
        })
    }

    render() {
        const {
            key,
            selections,
            name,
            description,
            coverSrc,
            filePath,
            categories,
            tags,
            isFree,
            order
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Видео"
                roles={[
                    'admin'
                ]}
            >
                <VideoForm
                    key={key}
                    method="put"
                    path={pathJoin('videos', id)}
                    selections={selections}
                    name={name}
                    description={description}
                    coverSrc={coverSrc}
                    filePath={filePath}
                    categories={categories}
                    tags={tags}
                    isFree={isFree}
                    order={order}
                />
            </Dashboard>
        )
    }
}
