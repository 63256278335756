import React, { Component } from 'react'
import randomstring from 'randomstring'
import { withStyles } from '@material-ui/styles'
import {
    Grid,
    Button,
    MenuItem,
    TextField,
    InputAdornment,
    IconButton
} from '@material-ui/core'
import { Sync } from 'mdi-material-ui'
import { Alert } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

class CouponForm extends Component {
    static defaultProps = {
        code: '',
        period: '',
        type: 'days'
    }

    constructor(props) {
        super(props)

        const {
            code,
            period,
            type
        } = props

        this.state = {
            errors: {},
            open: false,
            disabled: false,
            code,
            period,
            type
        }
    }

    generateCode = () => {
        const code = randomstring.generate({
            length: 7,
            capitalization: 'uppercase'
        })

        this.setState({ code })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    reset = () => {
        this.setState({
            code: '',
            period: '',
            type: 'days'
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            disabled: true
        })

        const {
            method,
            path
        } = this.props

        const {
            code,
            period,
            type
        } = this.state

        try {
            await axios[method](path, {
                code,
                period,
                type
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                open: true,
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            disabled,
            code,
            period,
            type
        } = this.state
        const { formControl } = this.props.classes

        return (
            <div>
                <Alert
                    open={open}
                    onClose={this.closeSnackbar}
                />
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={5}
                        xl={4}
                    >
                        <form
                            onSubmit={this.storeOrUpdate}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                label="Код"
                                value={code}
                                error={!!errors.code}
                                onChange={this.handleChange('code')}
                                variant="outlined"
                                margin="normal"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={this.generateCode}
                                            >
                                                <Sync />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                            <TextField
                                label="Хугацаа"
                                value={period}
                                error={!!errors.period}
                                onChange={this.handleChange('period')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Төрөл"
                                value={type}
                                error={!!errors.type}
                                onChange={this.handleChange('type')}
                                variant="outlined"
                                margin="normal"
                                children={[
                                    <MenuItem value="days">
                                        Хоног
                                    </MenuItem>,
                                    <MenuItem value="months">
                                        Сар
                                    </MenuItem>,
                                    <MenuItem value="years">
                                        Жил
                                    </MenuItem>
                                ]}
                                fullWidth
                                select
                            />
                            <div className={formControl}>
                                <Button
                                    disabled={disabled}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(CouponForm)
