import React, { Component } from 'react'
import {
    Avatar,
    Grid
} from '@material-ui/core'
import { Dashboard } from './../layouts'
import {
    ActionDelete,
    ActionNewButton,
    BaseTable,
    BaseTableActions
} from './../components'

const PathCell = ({ filePath }) => {
    const url = `https://images.weserv.nl/?url=${process.env.REACT_APP_API_URL}/${filePath}&w=40&h=40&fit=cover`
    return (
        <Avatar
            alt="avatar"
            className="table-item"
            src={url}
        />
    )
}

const columns = [
    {
        name: 'filePath',
        getCellValue: PathCell
    },
    {
        name: 'createdAt',
        title: 'Огноо'
    }
]

const dateColumns = [
    'createdAt'
]

const sortingStateColumnExtensions = [
    {
        columnName: 'filePath',
        sortingEnabled: false
    }
]

export default class Slides extends Component {
    constructor(props) {
        super(props)
        this.table = React.createRef()
    }

    fetchData = () => {
        return this.table.current.fetchData()
    }

    render() {
        return (
            <Dashboard
                title="Слайд"
                roles={[
                    'admin'
                ]}
            >
                <BaseTableActions>
                    <Grid item>
                        <ActionNewButton path="/slides/new" />
                    </Grid>
                    <Grid item>
                        <ActionDelete
                            path="/slides/mass/destroy"
                            onDelete={this.fetchData}
                        />
                    </Grid>
                </BaseTableActions>
                <BaseTable
                    path="slides"
                    columns={columns}
                    dateColumns={dateColumns}
                    sortingStateColumnExtensions={sortingStateColumnExtensions}
                    innerRef={this.table}
                />
            </Dashboard>
        )
    }
}
