import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Lesson as LessonForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class LessonsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            selections: [],
            name: '',
            coverSrc: '',
            filePath: '',
            isFree: false,
            subjects: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { data } = await axios.get('grades', {
            progress: true,
            params: {
                sortBy: 'name',
                sortOrder: 'asc',
                populate: [
                    'subjects'
                ]
            }
        })

        const { lesson } = await axios.get(pathJoin('lessons', id), { progress: true })
        const {
            name,
            coverSrc,
            filePath,
            isFree,
            subjects
        } = lesson

        this.setState({
            key: shortid.generate(),
            selections: data,
            name,
            coverSrc,
            filePath,
            isFree,
            subjects
        })
    }

    render() {
        const {
            key,
            selections,
            name,
            coverSrc,
            filePath,
            isFree,
            subjects
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Хичээл"
                roles={[
                    'admin'
                ]}
            >
                <LessonForm
                    key={key}
                    method="put"
                    path={pathJoin('lessons', id)}
                    selections={selections}
                    name={name}
                    coverSrc={coverSrc}
                    filePath={filePath}
                    isFree={isFree}
                    subjects={subjects}
                />
            </Dashboard>
        )
    }
}
