import React, { Component } from 'react'
import Moment from 'react-moment'

export default class FormatDate extends Component {
    render() {
        const {
            value
        } = this.props

        if (!value) {
            return null
        }

        return (
            <Moment
                locale="mn"
                format="YYYY-MM-DD hh:mm:ss"
            >
                {value}
            </Moment>
        )
    }
}
