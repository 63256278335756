import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Subject as SubjectForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class SubjectsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            selections: [],
            name: '',
            grade: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { data } = await axios.get('grades', {
            progress: true,
            params: {
                sortBy: 'name',
                sortOrder: 'asc'
            }
        })

        const { subject } = await axios.get(pathJoin('subjects', id), { progress: true })
        const {
            name,
            grade
        } = subject

        this.setState({
            key: shortid.generate(),
            selections: data,
            name,
            grade
        })
    }

    render() {
        const {
            key,
            selections,
            name,
            grade
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Хичээлийн төрөл"
                roles={[
                    'admin'
                ]}
            >
                <SubjectForm
                    key={key}
                    method="put"
                    path={pathJoin('subjects', id)}
                    selections={selections}
                    name={name}
                    grade={grade}
                />
            </Dashboard>
        )
    }
}
