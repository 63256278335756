import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core'
import { Alert } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

class QuestionForm extends Component {
    static defaultProps = {
        questionKey: '',
        value: ''
    }

    constructor(props) {
        super(props)

        const {
            questionKey,
            value
        } = props

        this.state = {
            errors: {},
            open: false,
            disabled: false,
            key: questionKey,
            value
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    reset = () => {
        this.setState({
            key: '',
            value: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            disabled: true
        })

        const {
            method,
            path
        } = this.props

        const {
            key,
            value
        } = this.state

        try {
            await axios[method](path, {
                key,
                value
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                open: true,
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            disabled,
            key,
            value
        } = this.state
        const { formControl } = this.props.classes

        return (
            <div>
                <Alert
                    open={open}
                    onClose={this.closeSnackbar}
                />
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={5}
                        xl={4}
                    >
                        <form
                            onSubmit={this.storeOrUpdate}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                label="Асуулт"
                                value={key}
                                error={!!errors.key}
                                onChange={this.handleChange('key')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Хариулт"
                                value={value}
                                error={!!errors.value}
                                onChange={this.handleChange('value')}
                                variant="outlined"
                                margin="normal"
                                rows="4"
                                multiline
                                fullWidth
                            />
                            <div className={formControl}>
                                <Button
                                    disabled={disabled}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(QuestionForm)
