import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Category as CategoryForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class CategoriesUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            name: '',
            description: '',
            coverSrc: '',
            order: 'A'
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { category } = await axios.get(pathJoin('categories', id), { progress: true })
        const {
            name,
            description,
            coverSrc,
            order
        } = category

        this.setState({
            key: shortid.generate(),
            name,
            description,
            coverSrc,
            order
        })
    }

    render() {
        const {
            key,
            name,
            description,
            coverSrc,
            order
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Ангилал"
                roles={[
                    'admin'
                ]}
            >
                <CategoryForm
                    key={key}
                    method="put"
                    path={pathJoin('categories', id)}
                    name={name}
                    description={description}
                    coverSrc={coverSrc}
                    order={order}
                />
            </Dashboard>
        )
    }
}
