import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import { Plan as PlanForm } from './../forms'

export default class PlansNew extends Component {
    render() {
        return (
            <Dashboard
                title="Багц"
                roles={[
                    'admin'
                ]}
            >
                <PlanForm
                    method="post"
                    path="plans"
                />
            </Dashboard>
        )
    }
}
