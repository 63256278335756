import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Checkbox,
    FormControlLabel,
    FormGroup
} from '@material-ui/core'

const styles = theme => ({
    //
})

class CheckBoxGroup extends Component {
    add = value => {
        const items = this.props.value.concat(value)
        this.props.onChange(items)
    }

    remove = value => {
        const items = this.props.value.filter(item => item !== value)
        this.props.onChange(items)
    }

    toggle = value => (e, checked) => {
        checked
            ? this.add(value)
            : this.remove(value)
    }

    render() {
        const {
            children,
            value: checkedValues
        } = this.props

        const createCheckBox = ({ value, children }) => (
            <div>
                <FormControlLabel
                    label={children}
                    control={
                        <Checkbox
                            checked={checkedValues.includes(value)}
                            onChange={this.toggle(value)}
                            value={value}
                        />
                    }
                />
            </div>
        )

        return (
            <div>
                <FormGroup>
                    {children(createCheckBox)}
                </FormGroup>
            </div>
        )
    }
}

export default withStyles(styles)(CheckBoxGroup)
