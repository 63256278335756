import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Grid,
    Button,
    TextField,
    FormControlLabel,
    FormLabel,
    Checkbox
} from '@material-ui/core'
import {
    Alert,
    CheckBoxGroup,
    DropZone
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

class LessonForm extends Component {
    static defaultProps = {
        selections: [],
        name: '',
        coverSrc: '',
        filePath: '',
        isFree: false,
        subjects: []
    }

    constructor(props) {
        super(props)

        const {
            selections,
            name,
            coverSrc,
            filePath,
            isFree,
            subjects
        } = props

        this.state = {
            errors: {},
            open: false,
            disabled: false,
            selections,
            name,
            coverSrc,
            filePath,
            isFree,
            subjects
        }
    }

    onChange = (path = '') => {
        this.setState({
            coverSrc: path
        })
    }

    onFileChange = (path = '') => {
        this.setState({
            filePath: path
        })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    handleCheckBoxChange = name => (e, checked) => {
        this.setState({
            [name]: checked
        })
    }

    setSubjects = value => {
        this.setState({
            subjects: value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    reset = () => {
        this.setState({
            name: '',
            coverSrc: '',
            filePath: '',
            isFree: false,
            subjects: []
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            disabled: true
        })

        const {
            method,
            path
        } = this.props

        const {
            name,
            coverSrc,
            filePath,
            isFree,
            subjects
        } = this.state

        try {
            await axios[method](path, {
                name,
                coverSrc,
                filePath,
                isFree,
                subjects
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                open: true,
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            disabled,
            selections,
            name,
            coverSrc,
            filePath,
            isFree,
            subjects
        } = this.state
        const { formControl } = this.props.classes

        return (
            <div>
                <Alert
                    open={open}
                    onClose={this.closeSnackbar}
                />
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={5}
                        xl={4}
                    >
                        <form
                            onSubmit={this.storeOrUpdate}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                label="Нэр"
                                value={name}
                                error={!!errors.name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <div className={formControl}>
                                <FormControlLabel
                                    label="Үнэгүй"
                                    control={
                                        <Checkbox
                                            checked={isFree}
                                            onChange={this.handleCheckBoxChange('isFree')}
                                        />
                                    }
                                />
                            </div>
                            <div className={formControl}>
                                <DropZone
                                    file={coverSrc}
                                    uploadPath="upload"
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className={formControl}>
                                <DropZone
                                    file={filePath}
                                    uploadPath="upload/file"
                                    onChange={this.onFileChange}
                                />
                            </div>
                            {selections.map(grade => {
                                if (!grade.subjects.length) {
                                    return null
                                }

                                return (
                                    <div className={formControl}>
                                        <FormLabel>
                                            {grade.name}
                                        </FormLabel>
                                        <CheckBoxGroup
                                            value={subjects}
                                            onChange={this.setSubjects}
                                        >
                                            {CheckBox =>
                                                <React.Fragment>
                                                    {grade.subjects.map(({ _id, name }) =>
                                                        <CheckBox key={_id} value={_id}>
                                                            {name}
                                                        </CheckBox>
                                                    )}
                                                </React.Fragment>
                                            }
                                        </CheckBoxGroup>
                                    </div>
                                )
                            })}
                            <div className={formControl}>
                                <Button
                                    disabled={disabled}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(LessonForm)
