import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    Grid,
    Button,
    TextField,
    MenuItem
} from '@material-ui/core'
import {
    Alert,
    DropZone
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

const letters = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
]

class CategoryForm extends Component {
    static defaultProps = {
        name: '',
        description: '',
        coverSrc: '',
        order: 'A'
    }

    constructor(props) {
        super(props)

        const {
            name,
            description,
            coverSrc,
            order
        } = props

        this.state = {
            errors: {},
            open: false,
            disabled: false,
            name,
            description,
            coverSrc,
            order
        }
    }

    onChange = (path = '') => {
        this.setState({
            coverSrc: path
        })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    reset = () => {
        this.setState({
            name: '',
            description: '',
            coverSrc: '',
            order: 'A'
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            disabled: true
        })

        const {
            method,
            path
        } = this.props

        const {
            name,
            description,
            coverSrc,
            order
        } = this.state

        try {
            await axios[method](path, {
                name,
                description,
                coverSrc,
                order
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                open: true,
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            disabled,
            name,
            description,
            coverSrc,
            order
        } = this.state
        const { formControl } = this.props.classes

        return (
            <div>
                <Alert
                    open={open}
                    onClose={this.closeSnackbar}
                />
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={5}
                        xl={4}
                    >
                        <form
                            onSubmit={this.storeOrUpdate}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                label="Нэр"
                                value={name}
                                error={!!errors.name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Тайлбар"
                                value={description}
                                error={!!errors.description}
                                onChange={this.handleChange('description')}
                                variant="outlined"
                                margin="normal"
                                rows="4"
                                multiline
                                fullWidth
                            />
                            <div className={formControl}>
                                <DropZone
                                    file={coverSrc}
                                    uploadPath="upload"
                                    onChange={this.onChange}
                                />
                            </div>
                            <TextField
                                label="Эрэмбэ"
                                value={order}
                                error={!!errors.order}
                                onChange={this.handleChange('order')}
                                variant="outlined"
                                margin="normal"
                                children={letters.map((letter, index) =>
                                    <MenuItem
                                        value={letter}
                                        key={index}
                                    >
                                        {letter}
                                    </MenuItem>
                                )}
                                fullWidth
                                select
                            />
                            <div className={formControl}>
                                <Button
                                    disabled={disabled}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(CategoryForm)
