import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Video as VideoForm } from './../forms'
import axios from './../plugins/axios'

export default class VideosNew extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            categories: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { data } = await axios.get('categories', {
            progress: true,
            params: {
                sortBy: 'name',
                sortOrder: 'asc'
            }
        })

        this.setState({
            key: shortid.generate(),
            categories: data
        })
    }
    
    render() {
        const {
            key,
            categories
        } = this.state

        return (
            <Dashboard
                title="Видео"
                roles={[
                    'admin',
                    'editor'
                ]}
            >
                <VideoForm
                    key={key}
                    selections={categories}
                    method="post"
                    path="videos"
                />
            </Dashboard>
        )
    }
}
