import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import { Grade as GradeForm } from './../forms'

export default class GradesNew extends Component {
    render() {
        return (
            <Dashboard
                title="Анги"
                roles={[
                    'admin'
                ]}
            >
                <GradeForm
                    method="post"
                    path="grades"
                />
            </Dashboard>
        )
    }
}
