import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'

class ExportCSV extends Component {
    render() {
        const {
            token,
            path
        } = this.props
        const uri = `${process.env.REACT_APP_API_URL}${path}?access_token=${token}`

        return (
            <Button
                color="primary"
                target="_blank"
                href={uri}
            >
                CSV татах
            </Button>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.token
})

export default connect(mapStateToProps)(ExportCSV)
