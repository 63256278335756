import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { Dashboard } from './../layouts'
import {
    ActionDelete,
    ActionNewButton,
    ActionUpdateButton,
    BaseTable,
    BaseTableActions,
    ExportCSV
} from './../components'
import { pathJoin } from './../utils/helpers'

const IsFreeCell = ({ isFree }) => (
    <span>
        {isFree ? 'Үнэгүй' : 'Үнэтэй'}
    </span>
)

const columns = [
    {
        name: 'name',
        title: 'Нэр'
    },
    {
        name: 'views',
        title: 'Үзсэн тоо'
    },
    {
        name: 'isFree',
        getCellValue: IsFreeCell
    },
    {
        name: 'order',
        title: 'Эрэмбэ'
    },
    {
        name: 'createdAt',
        title: 'Огноо'
    },
    {
        name: 'EDIT',
        getCellValue: ({ _id }) => <ActionUpdateButton path={pathJoin('videos', _id)} />
    }
]

const dateColumns = [
    'createdAt'
]

class Videos extends Component {
    constructor(props) {
        super(props)
        this.table = React.createRef()
    }

    fetchData = () => {
        return this.table.current.fetchData()
    }

    render() {
        const { user } = this.props

        return (
            <Dashboard
                title="Видео"
                roles={[
                    'admin',
                    'editor'
                ]}
            >
                <BaseTableActions>
                    <Grid item>
                        <ExportCSV path="/api/videos/export/csv" />
                    </Grid>
                    <Grid item>
                        <ActionNewButton path="/videos/new" />
                    </Grid>
                    {user && user.role === 'admin' &&
                        <Grid item>
                            <ActionDelete
                                path="/videos/mass/destroy"
                                onDelete={this.fetchData}
                            />
                        </Grid>
                    }
                </BaseTableActions>
                <BaseTable
                    path="videos"
                    columns={columns}
                    dateColumns={dateColumns}
                    innerRef={this.table}
                />
            </Dashboard>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
})

export default connect(mapStateToProps)(Videos)
