import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Product as ProductForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class ProductsUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            title: '',
            body: '',
            coverSrc: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { product } = await axios.get(pathJoin('products', id), { progress: true })
        const {
            title,
            body,
            coverSrc
        } = product

        this.setState({
            key: shortid.generate(),
            title,
            body,
            coverSrc
        })
    }

    render() {
        const {
            key,
            title,
            body,
            coverSrc
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Бүтээгдэхүүн"
                roles={[
                    'admin'
                ]}
            >
                <ProductForm
                    key={key}
                    method="put"
                    path={pathJoin('products', id)}
                    title={title}
                    body={body}
                    coverSrc={coverSrc}
                />
            </Dashboard>
        )
    }
}
