import React, { Component } from 'react'
import randomstring from 'randomstring'
import { withStyles } from '@material-ui/styles'
import {
    Grid,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel
} from '@material-ui/core'
import { Sync } from 'mdi-material-ui'
import {
    Alert,
    BaseDatePicker,
    BasePasswordField,
    DropZone
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

class UserForm extends Component {
    static defaultProps = {
        name: '',
        avatar: '',
        email: '',
        phone: '',
        password: '',
        expiryDate: null,
        role: 'user',
        aid: '',
        discountRate: '',
        commissionRate: ''
    }

    constructor(props) {
        super(props)

        const {
            name,
            avatar,
            email,
            phone,
            password,
            expiryDate,
            role,
            aid,
            discountRate,
            commissionRate
        } = props

        this.state = {
            errors: {},
            open: false,
            disabled: false,
            name,
            avatar,
            email,
            phone,
            password,
            expiryDate,
            role,
            aid,
            discountRate,
            commissionRate
        }
    }

    generateAid = () => {
        const aid = randomstring.generate({
            length: 6,
            capitalization: 'uppercase'
        })

        this.setState({ aid })
    }

    onChange = (path = '') => {
        this.setState({
            avatar: path
        })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    handleDateChange = name => date => {
        this.setState({
            [name]: date
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    reset = () => {
        this.setState({
            name: '',
            avatar: '',
            email: '',
            phone: '',
            password: '',
            expiryDate: null,
            role: 'user',
            aid: '',
            discountRate: '',
            commissionRate: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            disabled: true
        })

        const {
            method,
            path
        } = this.props

        const {
            name,
            avatar,
            email,
            phone,
            password,
            expiryDate,
            role,
            aid,
            discountRate,
            commissionRate
        } = this.state

        try {
            await axios[method](path, {
                name,
                avatar,
                email,
                phone,
                password,
                expiryDate,
                role,
                aid,
                discountRate,
                commissionRate
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                open: true,
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            disabled,
            name,
            avatar,
            email,
            phone,
            password,
            expiryDate,
            role,
            aid,
            discountRate,
            commissionRate
        } = this.state
        const { formControl } = this.props.classes

        return (
            <div>
                <Alert
                    open={open}
                    onClose={this.closeSnackbar}
                />
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={5}
                        xl={4}
                    >
                        <form
                            onSubmit={this.storeOrUpdate}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                label="Нэр"
                                value={name}
                                error={!!errors.name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="И-мэйл"
                                value={email}
                                error={!!errors.email}
                                onChange={this.handleChange('email')}
                                type="email"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Утасны дугаар"
                                value={phone}
                                error={!!errors.phone}
                                onChange={this.handleChange('phone')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Түншлэлийн код"
                                value={aid}
                                error={!!errors.aid}
                                onChange={this.handleChange('aid')}
                                variant="outlined"
                                margin="normal"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={this.generateAid}
                                            >
                                                <Sync />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                            <TextField
                                label="Түншлэлийн хямдралын хувь"
                                value={discountRate}
                                error={!!errors.discountRate}
                                onChange={this.handleChange('discountRate')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Түншлэлийн орлого бодох хувь"
                                value={commissionRate}
                                error={!!errors.commissionRate}
                                onChange={this.handleChange('commissionRate')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <BasePasswordField
                                label="Нууц үг"
                                value={password}
                                error={!!errors.password}
                                onChange={this.handleChange('password')}
                            />
                            <div className={formControl}>
                                <BaseDatePicker
                                    label="Хүчинтэй огноо"
                                    value={expiryDate}
                                    onChange={this.handleDateChange('expiryDate')}
                                />
                            </div>
                            <div className={formControl}>
                                <DropZone
                                    file={avatar}
                                    uploadPath="upload"
                                    onChange={this.onChange}
                                />
                            </div>
                            <FormControl margin="normal">
                                <RadioGroup
                                    name="role"
                                    value={role}
                                    onChange={this.handleChange('role')}
                                >
                                    <FormControlLabel
                                        value="admin"
                                        control={<Radio color="primary" />}
                                        label="Админ"
                                    />
                                    <FormControlLabel
                                        value="editor"
                                        control={<Radio color="primary" />}
                                        label="Видео менежер"
                                    />
                                    <FormControlLabel
                                        value="user"
                                        control={<Radio color="primary" />}
                                        label="Хэрэглэгч"
                                    />
                                </RadioGroup>
                            </FormControl>
                            <div className={formControl}>
                                <Button
                                    disabled={disabled}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(UserForm)
