import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import { Question as QuestionForm } from './../forms'

export default class QuestionsNew extends Component {
    render() {
        return (
            <Dashboard
                title="Түгээмэл асуулт"
                roles={[
                    'admin'
                ]}
            >
                <QuestionForm
                    method="post"
                    path="questions"
                />
            </Dashboard>
        )
    }
}
