import React, { Component } from 'react'
import { Dashboard } from './../layouts'
import { User as UserForm } from './../forms'

export default class UsersNew extends Component {
    render() {
        return (
            <Dashboard
                title="Хэрэглэгч"
                roles={[
                    'admin'
                ]}
            >
                <UserForm
                    method="post"
                    path="users"
                />
            </Dashboard>
        )
    }
}
