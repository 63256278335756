import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Subject as SubjectForm } from './../forms'
import axios from './../plugins/axios'

export default class SubjectsNew extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            grades: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { data } = await axios.get('grades', {
            progress: true,
            params: {
                sortBy: 'name',
                sortOrder: 'asc'
            }
        })

        this.setState({
            key: shortid.generate(),
            grades: data
        })
    }

    render() {
        const {
            key,
            grades
        } = this.state

        return (
            <Dashboard
                title="Хичээлийн төрөл"
                roles={[
                    'admin'
                ]}
            >
                <SubjectForm
                    key={key}
                    selections={grades}
                    method="post"
                    path="subjects"
                />
            </Dashboard>
        )
    }
}
