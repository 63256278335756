import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core'
import {
    Alert,
    BaseEditor,
    DropZone
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    }
})

class PostForm extends Component {
    static defaultProps = {
        title: '',
        body: '',
        coverSrc: ''
    }

    constructor(props) {
        super(props)

        const {
            title,
            body,
            coverSrc
        } = props

        this.state = {
            errors: {},
            open: false,
            disabled: false,
            key: shortid.generate(),
            title,
            body,
            coverSrc
        }
    }

    onChange = (path = '') => {
        this.setState({
            coverSrc: path
        })
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    handleEditorChange = name => e => {
        this.setState({
            [name]: e.target.getContent()
        })
    }

    closeSnackbar = () => {
        this.setState({
            open: false
        })
    }

    reset = () => {
        this.setState({
            key: shortid.generate(),
            title: '',
            body: '',
            coverSrc: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()

        this.setState({
            errors: {},
            disabled: true
        })

        const {
            method,
            path
        } = this.props

        const {
            title,
            body,
            coverSrc
        } = this.state

        try {
            await axios[method](path, {
                title,
                body,
                coverSrc
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                open: true,
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({
                    errors,
                    disabled: false
                })
            }
        }
    }

    render() {
        const {
            errors,
            open,
            disabled,
            key,
            title,
            body,
            coverSrc
        } = this.state
        const { formControl } = this.props.classes

        return (
            <div>
                <Alert
                    open={open}
                    onClose={this.closeSnackbar}
                />
                <Grid
                    spacing={2}
                    container
                >
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={5}
                        xl={4}
                    >
                        <form
                            onSubmit={this.storeOrUpdate}
                            autoComplete="off"
                            noValidate
                        >
                            <TextField
                                label="Гарчиг"
                                value={title}
                                error={!!errors.title}
                                onChange={this.handleChange('title')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <div className={formControl}>
                                <DropZone
                                    file={coverSrc}
                                    uploadPath="upload"
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className={formControl}>
                                <BaseEditor
                                    key={key}
                                    value={body}
                                    onChange={this.handleEditorChange('body')}
                                />
                            </div>
                            <div className={formControl}>
                                <Button
                                    disabled={disabled}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </div>
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(PostForm)
