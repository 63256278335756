import React, { Component } from 'react'
import shortid from 'shortid'
import { Dashboard } from './../layouts'
import { Plan as PlanForm } from './../forms'
import axios from './../plugins/axios'
import { pathJoin } from './../utils/helpers'

export default class PlansUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            key: shortid.generate(),
            name: '',
            description: '',
            period: '',
            type: '',
            price: '',
            productId: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { params } = this.props.match
        const { id } = params

        const { plan } = await axios.get(pathJoin('plans', id), { progress: true })
        const {
            name,
            description,
            period,
            type,
            price,
            productId
        } = plan

        this.setState({
            key: shortid.generate(),
            name,
            description,
            period,
            type,
            price,
            productId
        })
    }

    render() {
        const {
            key,
            name,
            description,
            period,
            type,
            price,
            productId
        } = this.state
        const { params } = this.props.match
        const { id } = params

        return (
            <Dashboard
                title="Багц"
                roles={[
                    'admin'
                ]}
            >
                <PlanForm
                    key={key}
                    method="put"
                    path={pathJoin('plans', id)}
                    name={name}
                    description={description}
                    period={period}
                    type={type}
                    price={price}
                    productId={productId}
                />
            </Dashboard>
        )
    }
}
