import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Dashboard } from './../layouts'

const styles = theme => ({
    //
})

class Settings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            //
        }
    }

    render() {
        const {
            //
        } = this.state

        return (
            <Dashboard
                title="Тохиргоо"
                roles={[
                    'admin'
                ]}
            >

            </Dashboard>
        )
    }
}

export default withStyles(styles)(Settings)
