import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { Dashboard } from './../layouts'
import {
    ActionDelete,
    ActionNewButton,
    ActionUpdateButton,
    BaseTable,
    BaseTableActions,
    ExportCSV
} from './../components'
import { pathJoin } from './../utils/helpers'

const PeriodCell = ({ period, type }) => {
    let translation = 'хоног'

    switch (type) {
        case 'months':
            translation = 'сар'
            break
        case 'years':
            translation = 'жил'
            break
    }

    return (
        <span>
            {period} {translation}
        </span>
    )
}

const IsUsedCell = ({ isUsed }) => (
    <span>
        {isUsed ? 'Тийм' : 'Үгүй'}
    </span>
)

const columns = [
    {
        name: 'code',
        title: 'Код'
    },
    {
        name: 'period',
        getCellValue: PeriodCell,
        title: 'Хугацаа'
    },
    {
        name: 'isUsed',
        getCellValue: IsUsedCell,
        title: 'Ашигласан эсэх'
    },
    {
        name: 'createdAt',
        title: 'Огноо'
    },
    {
        name: 'EDIT',
        getCellValue: ({ _id }) => <ActionUpdateButton path={pathJoin('coupons', _id)} />
    }
]

const dateColumns = [
    'createdAt'
]

const sortingStateColumnExtensions = [
    {
        columnName: 'period',
        sortingEnabled: false
    }
]

export default class Coupons extends Component {
    constructor(props) {
        super(props)
        this.table = React.createRef()
    }

    fetchData = () => {
        return this.table.current.fetchData()
    }

    render() {
        return (
            <Dashboard
                title="Бэлгийн карт"
                roles={[
                    'admin'
                ]}
            >
                <BaseTableActions>
                    <Grid item>
                        <ExportCSV path="/api/coupons/export/csv" />
                    </Grid>
                    <Grid item>
                        <ActionNewButton path="/coupons/new" />
                    </Grid>
                    <Grid item>
                        <ActionDelete
                            path="/coupons/mass/destroy"
                            onDelete={this.fetchData}
                        />
                    </Grid>
                </BaseTableActions>
                <BaseTable
                    path="coupons"
                    columns={columns}
                    dateColumns={dateColumns}
                    sortingStateColumnExtensions={sortingStateColumnExtensions}
                    innerRef={this.table}
                />
            </Dashboard>
        )
    }
}
