import React, { Component } from 'react'
import NumberFormat from 'react-number-format'

export default class FormatCurrency extends Component {
    render() {
        return (
            <NumberFormat
                displayType="text"
                value={this.props.value}
                thousandSeparator
            />
        )
    }
}
